import { postBodyRequest,getRequest } from '@/utils/axios'

export function page (type, params) {
  return postBodyRequest(`/bs/bpm/flow/instance/page/${type}`, params)
}

export function detail (id) {
  return postBodyRequest(`/bs/bpm/flow/instance/${id}`)
}
export function newDetail (id) {
  return postBodyRequest(`/bs/bpm/flow/instance/execute/${id}`)
}

export function start (params) {
  return postBodyRequest('/bs/bpm/flow/instance/start', params)
}

export function execute (params) {
  return postBodyRequest('/bs/bpm/flow/instance/execute', params)
}

export function taskRemark (params) {
  return postBodyRequest('/bs/bpm/flow/instance/task/remark', params)
}
export function copyRemark (params) {
  return postBodyRequest('/bs/bpm/flow/instance/copy/people', params)
}
export function copyPage (params) {
  return postBodyRequest('/bs/bpm/flow/instance/copy/people/page', params)
}
export function getFlowInstancePay (flowInstanceId,params) {
  return getRequest(`/pay/instance/pay/getFlowInstancePay?flowInstanceId=${flowInstanceId}`, params)
}
export function getFlowPayRecord (flowInstanceId,params) {
  return getRequest(`/pay/instance/pay/getFlowPayRecord?flowInstanceId=${flowInstanceId}`, params)
}
export function startFlowPay (params) {
  return postBodyRequest('/pay/instance/pay/startFlowPay', params)
}
export function offlinePay (params) {
  return postBodyRequest('/pay/instance/pay/offline', params)
}