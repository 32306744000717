<template>
  <div>
    <div v-show="payRecord.length > 0" style="margin: 5px 0;color:#0066CC">
      付款记录
      <span style="color:#f00">(注: 付款成功后10~20秒内生成付款记录，回单5~10分钟可查看下载。)</span>
    </div>
    <a-table v-show="payRecord.length > 0" :dataSource="payRecord" :loading="loading" bordered size="small"
      :row-key="record => record.flowInstanceId" :columns="columns" :pagination="false">
      <template #onlinePay="{ record }">
        <div>
          {{ record.onlinePay?.value === 1 ? '线上' : '线下' }}
        </div>
      </template>
      <template #previewUrl="{ record }">
        <div>
          <a-image v-for="(item, index) in record.fileUrls" :key="index" :width="20" :src="item.url" />
        </div>
      </template>
      <template #download="{ record }">
        <div v-show="record.bizOrderNo">
          <a @click="download(record)">查看</a>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { getFlowPayRecord } from '@/api/system/bpm/instance'
import { receiptDownload } from '@/api/carrier/cloudFlash'

export default {
  setup (props) {
    const state = reactive({
      loading: false,
      payRecord: [],
      columns: [
        {
          title: '金额',
          dataIndex: 'amt'
        },
        {
          title: '方式',
          dataIndex: '',
          slots: { customRender: 'onlinePay' }
        },
        {
          title: '银行卡',
          dataIndex: 'accountNum'
        },
        {
          title: '凭证',
          dataIndex: '',
          slots: { customRender: 'previewUrl' }
        },
        {
          title: '分账状态',
          dataIndex: 'splitStatus.label'
        },
        {
          title: '提现状态',
          dataIndex: 'withdrawStatus.label'
        },
        {
          title: '操作时间',
          dataIndex: 'createTime'
        },
        {
          title: '备注',
          width:'30%',
          dataIndex: 'remark'
        },
        {
          title: '回单',
          dataIndex: '',
          slots: { customRender: 'download' }
        }
      ]
    })
    const getFlowPayRecordData = (flowInstanceId) => {
      state.loading = true
      getFlowPayRecord(flowInstanceId).then(res => {
        if (res.code === 10000) {
          state.payRecord = res.data
        }
      }).finally(() => { state.loading = false })
    }
    const download = record => {
      receiptDownload({
        bizOrderNo: record.bizOrderNo
      }).then(res => {
        if (res.code === 10000) {
          window.open(res.data.url, '_blank')
        }
      })
    }
    return {
      ...toRefs(state),
      download,
      getFlowPayRecordData
    }
    defineExpose({
      getFlowPayRecordData
    })
  }
}
</script>

<style lang="less" scoped></style>