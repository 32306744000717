<template>
  <div>
    <a-modal :visible="modalVisible" :title="cancelType == 7?'作废':'红冲'" width="40%" @ok="sumbit" @cancel="cancel">
      <div>
          <div class="m-t2 m-b2"><span style="color:red;font-family: SimSun, sans-serif;"> *</span>上传截图</div>
          <a-upload v-model:file-list="fileList" accept="image/*" list-type="picture" :remove="handleRemove" :before-upload="beforeUpload">
          <a-button>
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
        <div class="m-t4">
          <div>备注：</div>
          <a-textarea v-model:value="invoiceValue" placeholder="" :rows="2" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { fileUpload as upload } from '@/api/marketing/modList'
import { CameraOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
const props = defineProps({
  modalVisible: {
    type: Boolean,
    default: false
  },
  cancelType:{
    type:String,
    default:0
  }
})

const isUpdateLoading = ref(false)
const fileList = ref([])
const fileListInfo = ref({})
const invoiceValue = ref('')
const emit = defineEmits(['invoiceOk','update:modalVisible'])
const beforeUpload = file => {
  updateBatch(file)
  return false
}
const cancel = () => {
  emit('update:modalVisible', false)
}
const handleRemove = (file) => {
  fileListInfo.value = {}
}
const key = 'updatable';
const updateBatch = (file) => {
  isUpdateLoading.value = true
  message.loading({ content: '上传中..', key });
  const formData = new FormData()
  formData.append('file', file)
  upload(formData).then((res) => {
    isUpdateLoading.value = false
    if (res.code === 10000) {
      fileListInfo.value = res.data
      message.success({ content: '上传成功', key, duration: 2 });
    } else {
      fileList.value = []
      fileListInfo.value = {}
      message.error({ content: '上传失败', key, duration: 2 });
    }
  })
}

const sumbit = () => {
  if(isUpdateLoading.value) return
  let msg = {
    invoiceFileId:fileListInfo.value.id,
    processRemark:invoiceValue.value
  }
  if(!msg.invoiceFileId){
    message.warn('请上传截图');
    return
  }
emit('invoiceOk', msg)
}
</script>

<style lang="less" scoped>
</style>