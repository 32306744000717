<template>
  <a-table :dataSource="dataSource" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="pagination" :rowKey="record => record.id" :loading="loading" bordered size="small" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
    <a-table-column :width="50" title="操作">
      <template #default="{ record }">
        <a-popconfirm title="确认绑定吗?" @confirm="bindIntercourse(record)">
          <a>绑定</a>
        </a-popconfirm>
      </template>
    </a-table-column>
    <a-table-column :width="100" title="唯一识别号" data-index="uniqueNumber" />
    <a-table-column :width="100" title="流水编号" data-index="accountNumber" />
    <a-table-column :width="100" title="交易日期" data-index="tradeTime" />
    <a-table-column :width="100" title="交易类别" data-index="tradeTypeName" />
    <a-table-column :width="100" title="交易金额" data-index="tradeAmt" />
    <a-table-column :width="100" title="交易用途" data-index="tradePurpose" />
    <a-table-column :width="100" title="我方账户" data-index="weAccount" />
    <a-table-column :width="100" title="对方账户" data-index="otherAccount" />
    <a-table-column :width="100" title="对方名称" data-index="otherName" />
    <a-table-column :width="100" title="对方开户行" data-index="otherBank" />
  </a-table>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { intercoursePage } from '@/api/trans/finance/account'
export default {
  props: {
    amts: { type: Array },
    amtTotal: { type: Number }
  },
  setup (props) {
    const state = reactive({
      loading: false,
      selectedRowKeys: [],
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10','30','60','80'],
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadIntercourseData()
        }
      }
    })

    const loadIntercourseData = () => {
      state.loading = true
      intercoursePage({
        amt: props.amts,
        confirm: 0,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(loadIntercourseData)

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    // const bindIntercourse = item => {
    //   bindFlow({
    //     flowInstanceId: props.flowId,
    //     intercourseIds: [item.id]
    //   }).then(res => {
    //     if (res.code === 10000) {
    //       message.success('绑定成功')
    //       state.chooseAmt += item.tradeAmt
    //     }
    //   }).finally(() => {
    //   })
    // }

    return {
      ...toRefs(state),
      onSelectChange
    }
  },
  components: {

  }
}
</script>
