<template>
  <a-card>
    <template v-if="flowType.value === 1 || flowType.value === 3 || flowType.value === 4">
      <template v-for="(item, index)  in bizParams.applyAccounts" :key="index">
        <a-descriptions :title="bizParams.applyAccounts?.length === 1 ? `付款对象-线下` : `付款对象-线下`">
          <a-descriptions-item label="款项">{{ item.projectName }}</a-descriptions-item>
          <a-descriptions-item></a-descriptions-item>
          <a-descriptions-item label="收款方式">{{ item.mod }}</a-descriptions-item>
          <a-descriptions-item label="金额">{{ item.amt }}</a-descriptions-item>
<!--          <a-descriptions-item label="银行名称">《待添加字段》</a-descriptions-item>-->
          <a-descriptions-item label="账号">{{ item.accountNum }}</a-descriptions-item>
          <a-descriptions-item label="户名">{{ item.accountName }}</a-descriptions-item>
          <a-descriptions-item label="开户行">{{ item.accountBank }}</a-descriptions-item>
          <a-descriptions-item label="收款码">
            <a-image v-if="item.payeeFileId" :width="20" :src="item.payeeFileUrl" />
          </a-descriptions-item>
          <a-descriptions-item label="收款方信息">
            {{ item.payeeInfo }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="备注">
            {{ flowInstancePay.remark }}
          </a-descriptions-item> -->
          <a-descriptions-item label="付款备注" v-if="index<=0">
            {{ bizParams.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <a-descriptions v-if="flowInstancePay && flowInstancePay.bizUserId" :title="`付款对象-线上`">
        <a-descriptions-item label="收款方类型">{{ flowInstancePay.objectType === 2 ? '承运商' : '客户' }}</a-descriptions-item>
        <a-descriptions-item :label="flowInstancePay.objectType === 2 ? '承运商名称' : '客户名称'">{{ flowInstancePay.objectName }}</a-descriptions-item>
        <a-descriptions-item label="账号别名">{{ flowInstancePay.memberAlias }}</a-descriptions-item>
        <a-descriptions-item label="银行卡号">{{ flowInstancePay.accountNum }}</a-descriptions-item>
      </a-descriptions>
    </template>
    <template v-if="flowType.value === 0 || flowType.value === 2">
      <a-descriptions title="付款对象" :extra="bizParams.collectionAccount.customerName" :column="2">
        <a-descriptions-item label="付款人">{{ bizParams.collectionAccount.payer }}</a-descriptions-item>
        <a-descriptions-item label="付款账户">{{ bizParams.collectionAccount.payWay }}</a-descriptions-item>
        <a-descriptions-item label="金额">{{ bizParams.collectionAccount.amt }}</a-descriptions-item>
        <a-descriptions-item label="付款时间">{{ bizParams.collectionAccount.paymentTime }}</a-descriptions-item>
        <a-descriptions-item label="付款截图" :span="2">
          <a-image v-if="bizParams.collectionAccount.paymentfileId" :width="50"
            :src="bizParams.collectionAccount.paymentfileUrl" />
        </a-descriptions-item>
        <a-descriptions-item label="备注" :span="2">
          <template v-if="flowType.value === 0">
            {{ bizParams.remark }}
          </template>
          <template v-if="flowType.value === 2">
            {{ bizParams.collectionAccount.remark }}
          </template>
          <template v-if="flowType.value === 0 && bizParams.collectionAccount">
            {{ bizParams.collectionAccount.remark }}
          </template>
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <a-table
      v-if="(flowType.value === 0 || flowType.value === 1 || flowType.value === 2 || flowType.value === 3) && bizParams.orderSettlementIds && bizParams.orderSettlementIds.length > 0"
      :data-source="bizParams.orderSettlementIds" :row-key="record => record.id" :pagination="false"
      :style="{ marginTop: '24px' }" bordered>
      <template #title><b>订单明细</b></template>
      <a-table-column title="订单编号" data-index="orderId" />
      <a-table-column title="订单金额" data-index="contractAmt" />
      <a-table-column title="结算类型" data-index="settlementMode.label" />
      <a-table-column title="本次结算金额" data-index="nowSettledAmt" />
      <a-table-column :width="120" title="小车信息" data-index="vehicleList">
        <template #default="{ record }">
          <div v-for="(item, index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column title="起始地" data-index="startAddress" />
      <a-table-column title="目的地" data-index="endAddress" />
      <a-table-column title="推送时间" data-index="startTime" />
      <a-table-column title="结算类型" data-index="orderSettlement.label" />
    </a-table>
<!--    <a-table v-if="flowType.value === 3 && bizParams.orderSettlementIds && bizParams.orderSettlementIds.length > 0"-->
<!--      :data-source="bizParams.orderSettlementIds" :row-key="record => record.id" :pagination="false"-->
<!--      :style="{ marginTop: '24px' }" bordered>-->
<!--      <template #title><b>订单明细</b></template>-->
<!--      <a-table-column title="订单编号" data-index="orderId" />-->
<!--      <a-table-column title="返款金额" data-index="refundAmt" />-->
<!--      <a-table-column title="本次结算金额" data-index="nowSettledAmt" />-->
<!--      <a-table-column title="起始地" data-index="startAddress" />-->
<!--      <a-table-column title="目的地" data-index="endAddress" />-->
<!--      <a-table-column title="推送时间" data-index="pushTime" />-->
<!--      <a-table-column title="结算类型" data-index="orderSettlement.label" />-->
<!--    </a-table>-->
    <a-table v-if="flowType.value === 4 && bizParams.orderSettlementIds && bizParams.orderSettlementIds.length > 0"
      :data-source="bizParams.orderSettlementIds" :row-key="record => record.id" 
      :style="{ marginTop: '24px' }" bordered>
      <template #title><b>订单明细</b></template>
      <a-table-column title="订单编号" data-index="orderId" />
      <a-table-column title="本次结算金额" data-index="nowSettledAmt" />
      <a-table-column title="车辆车牌" data-index="carNo" />
      <a-table-column title="费用名称" data-index="feeName" />
      <a-table-column title="费用添加时间" data-index="feeTime" />
      <a-table-column title="起始地" data-index="startAddress" />
      <a-table-column title="目的地" data-index="endAddress" />
    </a-table>
    <a-table v-if="bizParams.transSettlementIds && bizParams.transSettlementIds.length > 0"
      :data-source="bizParams.transSettlementIds" :columns="transColumns" :row-key="record => record.id"
      :style="{ marginTop: '24px' }" bordered>
      <template #title><b>运单明细</b></template>
      <template #transId="{ record }">
        <a-typography-text v-if="record.transId != null" :copyable="{ tooltips: false }" :content="record.transId" />
      </template>
      <template #transName="{ record }">
        <span @click="getVehicleDetails(record.transId)">{{ record.transName }}</span>
      </template>
      <template #vehicleLists="{ record }">
        <div v-for="(item, index) in record.vehicleList" :key="index">
          <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
        </div>
      </template>
      <template #settlementMode="{ record }">
        {{ record.settlementMode?.label }}<br /><a-tag v-if="record.settlementMode?.value === 3" color="red">{{ record.exceptionType?.label }}</a-tag>
      </template>
      <template #address="{ record }">
        <span>{{ record.startAddress }}</span><br />
        <span>{{ record.endAddress }}</span>
      </template>
    </a-table>
  </a-card>
  <a-modal v-model:visible="transDetailsVisible" width="80%" title="运单详情" footer="" destroy-on-close>
    <transDetails :transId="transId" />
  </a-modal>
</template>
<script>
import { reactive, toRefs } from 'vue'
import TransDetails from '@/views/system/flow/transDetails'
export default {
  props: {
    flowType: { type: Object },
    bizParams: { type: Object },
    flowInstancePay: Object
  },
  setup (props) {
    const state = reactive({
      transDetailsVisible: false,
      transId: '',
      transColumns: [
        { title: '运单编号', dataIndex: 'transId', key: 'transId', slots: { customRender: 'transId' } },
        { title: '类型', dataIndex: 'settlementMode', key: 'settlementMode', slots: { customRender: 'settlementMode' } },
        { title: '承运商', dataIndex: 'carrierName', key: 'carrierName' },
        { title: '车牌号/司机', dataIndex: 'transName', key: 'transName', slots: { customRender: 'transName' } },
        { title: '起始地-目的地', dataIndex: 'address', key: 'address', slots: { customRender: 'address' } },
        { title: '小车信息', dataIndex: 'vehicleLists', key: 'vehicleLists', slots: { customRender: 'vehicleLists' } },
        { title: '申请金额', dataIndex: 'nowSettledAmt', key: 'nowSettledAmt' },
        { title: '出发时间', dataIndex: 'startTime', key: 'startTime' },
        { title: '到达时间', dataIndex: 'endTime', key: 'endTime' }
      ]
    })

    const getVehicleDetails = (id) => {
      state.transId = id
      state.transDetailsVisible = true
    }

    return {
      ...toRefs(state),
      getVehicleDetails
    }
  },
  components: {
    TransDetails
  }
}
</script>
