<template>
  <a-card>
    <a-descriptions title="质损对象">
      <a-descriptions-item label="车架号">{{bizParams.orderVehicleVin}}</a-descriptions-item>
      <a-descriptions-item label="质损类型">{{qualityLossType}}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions>
      <a-descriptions-item label="是否理赔">{{bizParams.isClaim.label}}</a-descriptions-item>
      <a-descriptions-item label="理赔公司">{{bizParams.insuranceCompany}}</a-descriptions-item>
      <a-descriptions-item label="质损结算类型">{{bizParams.lossType.label}}</a-descriptions-item>
      <a-descriptions-item label="质损金额">{{bizParams.qualityLossAmt}}</a-descriptions-item>
      <a-descriptions-item label="账号" v-if="bizParams.lossType.value===0">{{bizParams.accountNum}}</a-descriptions-item>
      <a-descriptions-item label="户名" v-if="bizParams.lossType.value===0">{{bizParams.accountName}}</a-descriptions-item>
      <a-descriptions-item label="开户行" v-if="bizParams.lossType.value===0">{{bizParams.accountBank}}</a-descriptions-item>
      <a-descriptions-item label="付款账号类型" v-if="bizParams.lossType.value===0">{{bizParams.paymentAccountType}}</a-descriptions-item>
      <a-descriptions-item label="收款码" v-if="bizParams.fileId !== null">
        <a-image v-if="bizParams.fileUrl" :width="50" :src="bizParams.fileUrl" />
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions>
      <a-descriptions-item label="备注">{{bizParams.remark}}</a-descriptions-item>
    </a-descriptions>
  </a-card>
  <template v-if="flowType.value === 5 ">
    <template v-for="(item, index)  in bizParams.responsibilities" :key="index">
      <a-card>
        <a-descriptions :title=" bizParams.responsibilities.length === 1 ? `责任对象` : `责任对象${index+1}` " :column=4>
          <a-descriptions-item label="责任对象类型">{{item.responsibilityType.label}}</a-descriptions-item>
          <a-descriptions-item label="金额">{{item.amt}}</a-descriptions-item>
          <a-descriptions-item label="责任部门">{{item.objectName}}</a-descriptions-item>
          <a-descriptions-item label="责任人">{{item.personName}}</a-descriptions-item>
        </a-descriptions>
      </a-card>
    </template>
  </template>

</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
  props: {
    flowType: { type: Object },
    bizParams: { type: Object }
  },
  setup (props) {
    const state = reactive({
      qualityLossType: ''
    })
    const agree = () => {
      if (props.bizParams.qualityLossType != null) {
        props.bizParams.qualityLossType.forEach(element => {
          state.qualityLossType = state.qualityLossType + element + ' '
        })
      }
      // props.bizParams.responsibilities.forEach(element => {
      //   if (element.personName !== '') {
      //     element.personName = JSON.parse(element.personName)
      //   }
      // })
    }
    setTimeout(function () {
      agree()
    }, 1000)

    return {
      ...toRefs(state),
      agree
    }
  },
  components: {

  }
}
</script>
