<template>
  <div>
    <a-modal :visible="modalVisible" title="同意" width="40%" @ok="sumbit" @cancel="cancel">
      <FormList :fromList="formList" ref="formRef"></FormList>
      <div>
        <template v-if="invalidType.invoiceMethod&&invalidType.invoiceMethod.value == 2">
          <div class="m-t2 m-b2"><span style="color:red;font-family: SimSun, sans-serif;"> *</span>上传发票<span style="color: red;">（上传PDF类型的文件）</span></div>
        <a-upload :before-upload="beforeUpload" class="upload-list-inline" :remove="remove" v-model:file-list="fileList" accept=".pdf">
          <a-button v-if="fileList.length==0" type="primary">选择文件</a-button>
        </a-upload>
        </template>
        <template v-if="invalidType.invoiceMethod&&invalidType.invoiceMethod.value == 1">
          <div class="m-t2 m-b2"><span style="color:red;font-family: SimSun, sans-serif;"> *</span>上传发票</div>
        <a-upload :before-upload="beforeUpload" class="upload-list-inline" list-type="picture" @preview="previewFile"  :remove="remove" v-model:file-list="fileList" accept="image/*">
          <a-button v-if="fileList.length==0" type="primary">选择文件</a-button>
        </a-upload>
        </template>
        <div class="m-t4">
          <div>备注：</div>
          <a-textarea v-model:value="invoiceValue" placeholder="" :rows="2" />
        </div>
      </div>
    </a-modal>
     <!-- 图片预览 -->
     <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref ,watch} from 'vue'
import FormList from '@/components/formList/index.vue'
import { fileUpload as upload } from '@/api/marketing/modList'
import { message } from 'ant-design-vue'
const props = defineProps({
  modalVisible: {
    type: Boolean,
    default: false
  },
  invalidType:{
    type:Object,
    default:{}
  }
})
const previewVisible = ref(false)
const previewImage = ref('')
const isUpdateLoading = ref(false)
const fileList = ref([])
const fileListInfo = ref({})
const invoiceValue = ref('')
const formList = ref([
  {
    label: "发票号",
    name: "invoiceNum",
    type: "input",
    placeholder: "请输入",
    width: "100%",
    value: null,
    prop: "invoiceNum",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  }
]
)
watch(()=>props.modalVisible,(val) => {
  if(val){
    formList.value[0].label = '发票号' + '(' + props.invalidType.invoiceMethod?.label + ')'
  }
})
const emit = defineEmits(['invoiceOk','update:modalVisible'])
const beforeUpload = file => {
  updateBatch(file)
  return false
}
// 图片预览
const previewFile = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj))
  }
  previewImage.value = file.url || file.preview
  previewVisible.value = true
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const cancel = () => {
  emit('update:modalVisible', false)
}
const remove = (file) => {
  fileListInfo.value = {}
}
const key = 'updatable';
const updateBatch = (file) => {
  isUpdateLoading.value = true
  message.loading({ content: '上传中..', key });
  const formData = new FormData()
  formData.append('file', file)
  upload(formData).then((res) => {
    isUpdateLoading.value = false
    if (res.code === 10000) {
      fileListInfo.value = res.data
      message.success({ content: '上传成功', key, duration: 2 });
    } else {
      fileList.value = []
      fileListInfo.value = {}
      message.error({ content: '上传失败', key, duration: 2 });
    }
  })
}

const formRef = ref(null)
const sumbit = () => {
  if(isUpdateLoading.value) return
  let msg = {
    invoiceNum:formRef.value?.fromOptions.invoiceNum,
    invoiceFileId:fileListInfo.value.id,
    processRemark:invoiceValue.value
  }
if(!msg.invoiceNum){
  message.warn('请输入发票号')
  return
} 
if(props.invalidType.invoiceMethod?.value == 1){
  if(msg.invoiceNum.length !== 8){
    message.warn('请输入正确的发票号')
    return
  }

}
if(props.invalidType.invoiceMethod?.value == 2){
  if(msg.invoiceNum.length !== 20){
    message.warn('请输入正确的发票号')
    return
  }
}
if(!msg.invoiceFileId){
  message.warn('请上传发票文件')
  return
}
emit('invoiceOk', msg)
}
</script>

<style lang="less" scoped>
</style>