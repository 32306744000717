<template>
<div class="contenBox">
  <div>
    <span style="font-weight: bold;font-size: 16px;">发票抬头</span>
    <div class="flex wrap  " style="width: 100%;color: #6B778C;margin-top: 15px;">
         <div v-for="item in invoiceInfo" :key="item.id" style="width: 33%;">
              {{ item.title }}：{{ item.value }}
         </div>
         <template  v-if="state.customerInvoice.invoiceType">
            <span class="m-b0-5"  style="width: 33%;" >开票资料截图：<a v-if="state.customerInvoice.screenshotIds.length>0" @click="lookImg('screenshotIds',state.customerInvoice)">查看</a></span>
            <span class="m-b0-5" style="width: 33%;"  v-if="state.customerInvoice.invoiceType.value == 3">一般纳税人资格证：<a v-if="state.customerInvoice.taxpayerFileId" @click="lookImg('taxpayerFileId',state.customerInvoice)">查看</a></span>
            <span  style="width: 33%;"  v-if="state.customerInvoice.invoiceType.value == 3">营业执照：<a v-if="state.customerInvoice.licenseFileId" @click="lookImg('licenseFileId',state.customerInvoice)">查看</a></span>
            <span  style="width: 33%;" class="m-b0-5"  v-if="state.customerInvoice.invoiceType.value == 2">组织机构代码证照片：<a v-if="state.customerInvoice.licenseFileId" @click="lookImg('licenseFileId',state.customerInvoice)">查看</a></span>
          </template>
    </div>
    <div style="font-weight: bold;font-size: 16px;margin-top: 20px;" >开票信息</div>

    <div class="flex wrap ju-between  " style="width: 100%;color: #6B778C;margin-top: 15px">
         <div v-for="item in billingInfo" :key="item.id" style="width: 33%;">
              {{ item.title }}：{{ item.value }}<span v-if="item.title == '税率'" style="color:red">（本月可用额度{{ availableCredit }}）</span>
         </div>
    </div>

    <template v-if="invoiceResult[0].value">
      <div  style="font-weight: bold;font-size: 16px;margin:15px 0">开票结果</div>

    <div class="flex wrap ju-between  " style="width: 100%;color: #6B778C;margin-top: 15px">
         <div v-for="item in invoiceResult" :key="item.id" style="width: 33%;">
          {{ item.title }}：  <span v-if="item.title !== '发票'"> {{ item.value }}</span><a @click="lookImg('',item.value)" v-if="item.title == '发票'&&item.value" >查看</a>
         </div>
    </div>
    </template>
    <div style="font-weight: bold;font-size: 16px;margin:15px 0" >开票合同</div>
     <template v-if="state.customerContract.length>0">
      <a-table
     :rowKey="(record, index) => { return index;}"
      bordered
      :columns="columns"
      :data-source="state.customerContract"
      size="small"
      :pagination="false"
     >
     <template #settlementMode="{record }">
            {{ record.settlementMode.label }}
     </template>
      <template #contractTime="{record }">
        {{ record.contractStartTime }} - {{record.contractEndTime}}
      </template>
     <template #settlementCycle="{record }">
       {{record.settlementCycle}}{{record.settlementMode.value == 2?'月':'天'}}
     </template>
     <template #fileUrl="{record }">
           <span v-if="record.fileIds.length > 0"> <a @click="lookImg('',record.fileIds)">查看</a></span>
           <span v-else>无</span>
     </template>
     </a-table>
     </template>
     <template v-if="state.contractFileIds.length>0">
        <span>合同照片：<a @click="lookImg('',state.contractFileIds)">查看</a></span>
     </template>
    <div style="font-weight: bold;font-size: 16px;margin:15px 0" >开票车辆</div>
    <a-table
     :rowKey="(record, index) => { return index;}"
      bordered
      :columns="columnsOrder"
      :data-source="state.vehicleInvoices"
      size="small"
      :pagination="false"
     >
     <template #vinNo="{ record } ">
      {{ record.vinNo }} {{ record.brand }} {{ record.model }}
     </template>
     <template #startAddress="{ record }">
       <div>起始地：{{ record.startAddress }}</div>
       <div>目的地：{{ record.endAddress}}</div>
     </template>
     <template #settlementStatus="{ record }">
      {{ record.settlementStatus.label }}
     </template>
     </a-table>
  </div>
   <!-- 自定义图片预览 -->
   <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></PictureViewer>
</div>
</template>

<script setup>
import { ref,onMounted,reactive } from 'vue'
import { provideUsableQuota } from '@/api/trans/finance/account'
import PictureViewer from '@/components/PictureViewer';
const props = defineProps({
  bizParams:{
    type:Object,
    default:{}
  },

})
const lookImgID = ref([])
const visibleImgPic = ref(false)
const state = reactive ({
  customerInvoice:props.bizParams.customerInvoice,
  invoiceSubject:props.bizParams.invoiceSubject,
  customerContract:props.bizParams.customerContract?[props.bizParams.customerContract]:[],//合同
  vehicleInvoices:props.bizParams.vehicleInvoices,  //车辆
  contractFileIds:props.bizParams.contractFileIds?props.bizParams.contractFileIds:[],
})
const availableCredit = ref('')
    const reqProvideUsableQuota = (id) => {
      provideUsableQuota(id).then( res => {
        availableCredit.value = res.data
      })
    }
// 预览图片
const lookImg = (str,item) => {
 if(str){
  if(typeof item[str] === 'string'){
   lookImgID.value = [item[str]]
   visibleImgPic.value = true
 }else{
   lookImgID.value = item[str]
   visibleImgPic.value = true
 }
 }else{
  if(typeof item === 'string'){
   lookImgID.value = [item]
   visibleImgPic.value = true
 }else{
   lookImgID.value = item
   visibleImgPic.value = true
 }
 }
}
// 开票结果
const invoiceResult = ref([
  {
    title: '发票号',
    value: '',
    key:`invoiceNum`,
  },
  {
    title: '发票',
    value: '',
    key:`invoiceFileId`,
  },
  {
    title: '开票时间',
    value: '',
    key:`invoiceFileTime`,
  },
  {
    title: '备注',
    value: '',
    key:`processRemark`,
  }
])
//发票抬头
const invoiceInfo = ref([
  {
    title: '抬头类型',
    value: '',
    key:`invoiceType`,
    obj:'label',
  },
  {
    title: '发票名称',
    value: '',
    key:'name'
  },
  {
    title: '税号',
    value: '',
    key:'taxIdentNum'
  },
  {
    title: '电话',
    value: '',
    key:'phone'
  },
  {
    title: '地址',
    value: '',
    key:'address'
  },
  {
    title: '开户行',
    value: '',
    key:'bank'
  },
  {
    title: '银行卡号',
    value: '',
    key:'account'
  },
])
// 开票信息
const billingInfo = ref([
  {
    title: '开票类型',
    value: '',
    key:'invoiceMethod',
    obj:'label'
  },
  {
    title: '票类',
    value: '',
    key:'ticketCategory',
    obj:'label'
  },
  {
    title: '专票金额',
    value: '',
    key:'amt',
  },
  {
    title: '主体',
    value: '',
    key:'invoiceCarrier',
  },
  {
    title: '税率',
    value: '',
    key:'taxRate',
  },
  {
    title: '税目',
    value: '',
    key:'taxItem',
  },
  {
    title: '备注',
    value: '',
    key:'remark',
  },
])
const MENU = {
  1:'身份证号',
  2:'组织机构代码',
  3:'税号'
}
const columns = ref( [
  {
    title: '合同名称',
    dataIndex: 'contractName',
    key:'contractName',
    align:'center',
  },
  {
    title: '合同编号',
    dataIndex: 'contractCode',
    key:'contractCode',
    align:'center',
    width:"100px"
  },
  {
    title: '结算方式',
    dataIndex: 'settlementMode',
    key:'settlementMode',
    slots:{customRender:'settlementMode' },
    align:'center',
  },
  {
    title: '结算周期',
    dataIndex: 'settlementCycle',
    slots:{customRender:'settlementCycle' },
    align:'center',
  },
  {
    title: '起止日期',
    dataIndex: 'contractTime',
    key:'contractTime',
    slots: { customRender: 'contractTime' },
    align:'center',
  },
  {
    title: '签约时间',
    dataIndex: 'createTime',
    key:'createTime',
    align:'center',
  },
  {
    title: '合同照片',
    dataIndex: 'fileUrl',
    key:'fileUrl',
    align:'center',
    slots:{customRender:'fileUrl'}
  },
]);
const columnsOrder = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key:'vinNo',
    align:'center',
    slots:{customRender:'vinNo'}
  },
  {
    title: '起点-终点',
    dataIndex: 'startAddress',
    key:'startAddress',
    align:'center',
   slots:{customRender:'startAddress'}
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key:'customerName',
    align:'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key:'orderId',
    align:'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '合同金额',
    dataIndex: 'invoiceAmt',
    key:'invoiceAmt',
    align:'center',
  },
  {
    title: '开票税目',
    dataIndex: 'taxItem',
    align:'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key:'settlementStatus',
    align:'center',
    slots:{
      customRender:'settlementStatus'
    }
  },
])
onMounted(()=> {
  reqProvideUsableQuota(props.bizParams.invoiceSubject?.invoiceId)
  invoiceInfo.value[2].title =  MENU[state.customerInvoice.invoiceType.value]
  invoiceInfo.value.forEach( item => {
    if(item.obj){
      if(state.customerInvoice[item.key]){
        item.value = state.customerInvoice[item.key][item.obj]
      }
    }else{
      item.value = state.customerInvoice[item.key]
    }
  })
  invoiceResult.value.forEach( item => {
    if(state.invoiceSubject[item.key]){
      item.value = state.invoiceSubject[item.key]
    }

  })
  billingInfo.value.forEach( item => {
    if(item.obj){
      if(state.invoiceSubject[item.key]){
        item.value = state.invoiceSubject[item.key][item.obj]
      }
    }else{
      if(item.title == '税率'){
        item.value  =  state.invoiceSubject[item.key] + '%'
        }else{
          item.value = state.invoiceSubject[item.key]
        }
    }
  })
})
</script>

<style lang="less" scoped>
.contenBox{
  background: #fff;
  padding: 20px 10px;
}
</style>
