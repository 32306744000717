<template>
  <a-table :row-key="record => record.vehicleId" :dataSource="listData" v-model:columns="columns" :pagination="false">
    <template #brand_model="{ record }">
      {{record.brand}} {{record.model}}
    </template>
  </a-table>
</template>
<script>
import { vehicleTruckAll } from '@/api/transport/vehicle'
import { onMounted, reactive, toRefs } from 'vue'

export default ({
  props: {
    transId: { type: String }
  },
  setup (props) {
    const state = reactive({
      loading: false,
      pagination: {},
      columns: [
        {
          title: '车辆型号',
          dataIndex: 'brand_model',
          slots: {
            customRender: 'brand_model'
          }
        },
        {
          title: '小车车牌',
          dataIndex: 'vinNo',
          key: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '单台运费',
          dataIndex: 'transportFee',
          key: 'transportFee',
          ellipsis: true
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          ellipsis: true
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          key: 'loadingOperator',
          ellipsis: true
        },
        {
          title: '起始地',
          dataIndex: 'startAddress',
          key: 'startAddress',
          ellipsis: true
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress',
          ellipsis: true
        }
      ],
      listData: []
    })

    const loadData = () => {
      state.loading = true
      vehicleTruckAll(props.transId, {}).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      })
    }
    onMounted(loadData)

    const getTransDetail = (id) => {
      state.modalVisible = true
      // vehicleTransDetail(id, {
      // }).then(res => {
      //   if (res.code === 10000) {
      //     state.listVehicleTransData = res.data
      //   }
      // })
    }
    return {
      ...toRefs(state),
      getTransDetail
    }
  }
})
</script>
