<template>
  <div style="position:reactive">
    <a-page-header class="ant-page-header" :title="`${instance.sponsor} 提交的（${flowType.label}）结算申请`" :ghost="false">
      <a-descriptions>
        <a-descriptions-item label="实例编号">
          <a-typography-paragraph copyable>
            {{ instance.instanceId }}
          </a-typography-paragraph>
        </a-descriptions-item>
        <a-descriptions-item label="申请部门">{{ instance.orgName }}</a-descriptions-item>
        <a-descriptions-item label="其他图片">
          <a-image :width="50" :src="item" v-for=" (item, index) in instance.fileUrls" :key="instance.fileIds[index]" />
        </a-descriptions-item>
        <a-descriptions-item v-if="type === 'myPending'">
        </a-descriptions-item>
        <a-descriptions-item v-if="type === 'myPending'">
        </a-descriptions-item>
        <a-descriptions-item v-if="type === 'myPending'">
          <a-button type="primary" shape="round" @click="remarkTask">标记</a-button>
        </a-descriptions-item>
      </a-descriptions>
    </a-page-header>
  
    <a-row :gutter="[16, 16]" :style="{ marginTop: '4px', marginBottom: '40px' }">
      <a-col :xs="24" :sm="24" :md="16" :lg="18">
        <!-- 组件 -->
        <component :is="assemblyType" :availableCredit="availableCredit" :flowType="flowType" :bizParams="bizParams"
          :flowInstancePay="executeParams.flowInstancePay" />
      </a-col>
      <a-col :xs="24" :sm="24" :md="8" :lg="6">
        <a-card title="流程">
          <a-steps direction="vertical">
            <template v-for="(item, index) in taskList" :key="index">
              <a-step :title="item.nodeName">
                <template #icon>
                  <a-avatar shape="square"
                    src="https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar">
                    <template #icon>
                      <CheckCircleFilled />
                    </template>
                  </a-avatar>
                </template>
                <template #subTitle>
                  <span style="float:right">{{ item.nodeHandleTime }}</span>
                </template>
                <template #description>
                  {{ item.handler }}
                  <template v-if="index > 0">
                    <template v-if="item.approveType">
                      <span>({{ item.approveType.label }})</span>
                    </template>
                    <template v-else-if="!item.approveType && item.current.value">
                      （审核中）
                    </template>
                    <template v-else>
                      （待审核）
                    </template>
                  </template>
                  <a-card v-if="item.approveRemark">
                    {{ item.approveRemark }}
                  </a-card>
                  <div v-if="item.mimeType === 'pdf'">
                    <a @click="lookImg(item.fileId)">查看</a>
                  </div>
                  <div v-else><a-image v-if="item.fileId" :width="50" :src="item.fileUrl" /></div>
                </template>
              </a-step>
            </template>
          </a-steps>
        </a-card>
      </a-col>
    </a-row>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-space>
        <a-button v-if="executeParams.flowInstancePay.payCount > 0" type="primary" shape="round" @click="paymentDetail"
          ghost>付款明细</a-button>
        <template v-if="type !== ''">
          <a-button type="primary" shape="round" @click="copyFlow" ghost>抄送</a-button>
        </template>
        <a-button v-show="instance.flowInstanceStatus?.value !== 5" type="primary" shape="round" @click="remarkFlow"
          ghost>评论</a-button>
        <template
          v-if="type === 'myFlow' && instance.flowInstanceStatus?.value !== 5 && instance.flowInstanceStatus?.value && instance.flowInstanceStatus?.value !== 6">
          <a-button shape="round" @click="reject">撤销</a-button>
        </template>
        <template v-if="type === 'myPending' && instance.handType !== 1">
          <a-button shape="round" @click="reject">退回</a-button>
        </template>
        <template v-if="flowInstanceTaskId">
          <a-button v-if="instance.handType !== 1" type="primary" shape="round" @click="refuse" ghost>拒绝</a-button>
          <!-- <a-button type="primary" shape="round" @click="agree">同意</a-button> -->
          <span v-if="instance.handType==2&&flowType.value == 6">
            <a-button type="primary" shape="round"  @click="agreeInvoice">同意</a-button>
          </span>
          <span v-else-if="instance.handType==2&&flowType.value == 7||instance.handType==2&&flowType.value == 8">
            <a-button type="primary" shape="round"  @click="agreeCancel">同意</a-button>
          </span>
          <span v-else>
            <a-button v-show="instance.handType !== 12" type="primary" shape="round" @click="agree">同意</a-button>
          </span>
          <a-button v-show="executeParams.flowInstancePay?.onlinePay?.value === 1 && instance.handType == 12"
            type="primary" shape="round" @click="onlineShow = true">同意(线上)</a-button>
          <a-button v-show="executeParams.flowInstancePay?.onlinePay?.value === 0 && instance.handType == 12"
            type="primary" shape="round" @click="bankFromShow = true">同意(线下)</a-button>
        </template>
      </a-space>
    </div>
    <div v-show="loading" class="loading-div"><a-spin size="large" /></div>
  </div>
  <!-- 发票同意 -->
  <div>
    <InvoiceModal @invoiceOk="invoiceOk" v-model:modalVisible="visibleInvoice" :invalidType="bizParams.invoiceSubject"></InvoiceModal>
  </div>
  <!-- 作废||红冲同意 -->
  <div>
    <CancelModal v-model:modalVisible="visibleCancel" @invoiceOk="invoiceOkCancel" :cancelType="flowType.value"></CancelModal>
  </div>
  <!-- 新流程 -->
  <a-modal v-model:visible="newModalVisible" :title="modalTitle" width="60%" footer="">
    <div>
      <!-- v-show="executeParams.flowInstancePay && executeParams.flowInstancePay.bizUserId && instance.handType === 11" -->
      <div>
        <div class="content-div">
          <div class="show-content">
            <label class="title-label">收款方类型：</label>
            <span class="content">{{ executeParams.flowInstancePay?.objectType === 2 ? '承运商' : '客户' }}</span>
          </div>
          <div class="show-content">
            <label class="title-label">名称：</label>
            <span class="content">{{ executeParams.flowInstancePay?.objectName }}</span>
          </div>
          <div class="show-content">
            <label class="title-label">账号别名：</label>
            <span class="content">{{ executeParams.flowInstancePay?.memberAlias }}</span>
          </div>
          <div class="show-content">
            <label class="title-label">申请总金额：</label>
            <span class="content">￥{{ procedureData.amt || 0 }}元</span>
          </div>
          <div class="show-content">
            <label class="title-label">已付款金额：</label>
            <span class="content" style="color:#0C8226">￥{{ procedureData.settledAmt || 0 }}元</span>
          </div>
          <div class="show-content">
            <label class="title-label">未付款金额：</label>
            <span class="content" style="color:#D9001B">￥{{ procedureData.notSettledAmt || 0 }}元</span>
          </div>
        </div>
        <PaymentRecord ref="paymentRecordRef" />

        <a-button class="pay-btn" v-show="procedureData.notSettledAmt > 0" @click="startPayShow = true">发起付款</a-button>
        <a-button class="pay-btn" v-show="procedureData.notSettledAmt <= 0" @click="agreeShow = true">同意</a-button>
      </div>
    </div>
  </a-modal>
  <!-- 前流程 -->
  <a-modal v-model:visible="modalVisible" :title="modalTitle" width="60%" @ok="handleOk"
    :bodyStyle="{ height: '60vh', overflow: 'auto' }" :maskClosable="false" centered>
    <div>
      <a-form :model="executeParams.flowInstanceParams" layout="vertical">
        <a-form-item
          v-show="instance.handType === 11 && executeParams.approveType.value === 1 && executeParams.flowInstancePay?.bizUserId">
          <template #label>
            <div>
              选择收款方式<span style="color:#f00">*</span>
            </div>
          </template>
          <div style="margin-bottom:5px">
            <span>收款方类型：{{ executeParams.flowInstancePay?.objectType === 2 ? '承运商' : '客户' }}</span>
            <p>名称：{{ executeParams.flowInstancePay?.objectName }}</p>
          </div>
          <!-- <a-select style="width: 120px" @change="payTypeChange">
            <a-select-option :value="1">线上付款</a-select-option>
            <a-select-option :value="0">线下付款</a-select-option>
          </a-select> -->
          <div v-show="executeParams.flowInstancePay.onlinePay === 1" style="margin-top:10px">
            <div>收款方类型：{{ executeParams.flowInstancePay?.objectType === 2 ? '承运商' : '客户' }}</div>
            <div>客户/承运商名称：{{ executeParams.flowInstancePay?.objectName }}</div>
            <div>通联账号：{{ executeParams.flowInstancePay?.bizUserId }}</div>
            <div>银行卡号：{{ executeParams.flowInstancePay?.accountNum }}</div>
            <div>别名：{{ executeParams.flowInstancePay?.memberAlias }}</div>
          </div>
          <div v-show="executeParams.flowInstancePay.onlinePay === 0" style="margin-top:10px;">
            <div style="color:#6B778C">
              <div>收款方式：{{ executeParams.flowInstancePay?.mode }}</div>
              <div>户名：{{ executeParams.flowInstancePay?.accountName }}</div>
              <div>备注：{{ executeParams.flowInstancePay?.remark }}</div>
              <div>收款码：<a-image :width="40" :src="executeParams.flowInstancePay?.payeeFileUrl" /></div>
            </div>
            <div style="color:#6B778C">
              <div>账号：{{ executeParams.flowInstancePay?.accountNum }}</div>
              <div>开户行：{{ executeParams.flowInstancePay?.accountBank }}</div>
              <div>其他照片： <a-image :width="40"
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" /></div>
            </div>
          </div>
        </a-form-item>
        <a-form-item v-model:value="remarkShow">
          <a-textarea v-model:value="executeParams.flowInstanceParams.remark" :placeholder="remakePlaceholder"
            :rows="4" />
        </a-form-item>
        <a-upload v-if="modalVisible" v-model:file-list="imgFileList" accept="image/*" list-type="picture"
          :remove="handleRemove" :before-upload="beforeUpload">
          <a-button :disabled="imgFileList.length > 0">
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
      </a-form>
      <!-- executeParams.approveType.value === 1 -->
    </div>
    <a-card v-if="executeParams.approveType.value === 1 && instance.handType === 1" :bordered="false"
      :bodyStyle="{ padding: '24px 0' }">
      <template #title>
        <a-form :model="form" :labelCol="{ flex: '100px' }" :wrapperCol="{ flex: 'calc(100%-100px)' }">
          <a-row>
            <a-col :span="8">
              <a-form-item label="识别码">
                <a-input v-model:value="form.uniqueNumber" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="流水编号">
                <a-input v-model:value="form.accountNumber" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="交易类别">
                <a-input v-model:value="form.tradeTypeName" />
              </a-form-item>
            </a-col>
            <a-col :span="16">
              <a-form-item label="交易金额" :wrapperCol="{ flex: 'calc(100%-100px)' }">
                <a-input-group compact>
                  <a-input-number v-model:value="form.tradeAmtStart" style="width: calc(50% - 20px); text-align: center"
                    placeholder="最小金额" />
                  <a-input style="width: 40px; border-left: 0; pointer-events: none; background-color: #fff"
                    placeholder="~" disabled />
                  <a-input-number v-model:value="form.tradeAmtEnd"
                    style="width: calc(50% - 20px); text-align: center; border-left: 0" placeholder="最大金额" />
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24" style="text-align: right;">
              <a-space>
                <a-button type="primary" @click="initLoadIntercourseData()">查询</a-button>
              </a-space>
            </a-col>
          </a-row>
        </a-form>
      </template>
      <div>已选：
        <div>总金额：{{ chooseAmt }}</div>
        <a-tag v-for="(item, index) in selectedRows" :key="index" @dblclick="delSelectedRow(item.id)" color="red"
          style="cursor: pointer;">唯一识别号：{{ item.uniqueNumber }} 金额：{{ item.tradeAmt }}</a-tag>
      </div>
      <a-table :dataSource="turnoverData" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="pagination" :rowKey="record => record.id" :loading="turnoverLoading" bordered size="small"
        @change="tableChange" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <a-table-column :width="100" title="唯一识别号" data-index="uniqueNumber" />
        <a-table-column :width="100" title="流水编号" data-index="accountNumber" />
        <a-table-column :width="100" title="交易日期" data-index="tradeTime" />
        <a-table-column :width="100" title="交易类别" data-index="tradeTypeName" />
        <a-table-column :width="100" title="交易金额" data-index="tradeAmt" />
        <a-table-column :width="100" title="交易用途" data-index="tradePurpose" />
        <a-table-column :width="100" title="我方账户" data-index="weAccount" />
        <a-table-column :width="100" title="对方账户" data-index="otherAccount" />
        <a-table-column :width="100" title="对方名称" data-index="otherName" />
        <a-table-column :width="100" title="对方开户行" data-index="otherBank" />
      </a-table>
    </a-card>
  </a-modal>
  <a-modal v-model:visible="agreeShow" title="流程同意" width="40%" footer="" :maskClosable="false" centered>
    <div>
      <a-form :model="executeParams.flowInstanceParams" layout="vertical">
        <a-form-item
          v-show="instance.handType === 11 && executeParams.approveType.value === 1 && executeParams.flowInstancePay?.bizUserId">
          <!-- <template #label>
            <div>
              选择收款方式<span style="color:#f00">*</span>
            </div>
          </template> -->
          <div style="margin-bottom:5px">
            <span>收款方类型：{{ executeParams.flowInstancePay?.objectType === 2 ? '承运商' : '客户' }}</span>
            <p>名称：{{ executeParams.flowInstancePay?.objectName }}</p>
          </div>
          <!-- <a-select style="width: 120px" @change="payTypeChange">
            <a-select-option :value="1">线上付款</a-select-option>
            <a-select-option :value="0">线下付款</a-select-option>
          </a-select> -->
          <div v-show="executeParams.flowInstancePay.onlinePay === 1" style="margin-top:10px">
            <div>收款方类型：{{ executeParams.flowInstancePay?.objectType === 2 ? '承运商' : '客户' }}</div>
            <div>客户/承运商名称：{{ executeParams.flowInstancePay?.objectName }}</div>
            <div>通联账号：{{ executeParams.flowInstancePay?.bizUserId }}</div>
            <div>银行卡号：{{ executeParams.flowInstancePay?.accountNum }}</div>
            <div>别名：{{ executeParams.flowInstancePay?.memberAlias }}</div>
          </div>
          <div v-show="executeParams.flowInstancePay?.onlinePay === 0" style="margin-top:10px;">
            <div style="color:#6B778C">
              <div>收款方式：{{ executeParams.flowInstancePay?.mode }}</div>
              <div>户名：{{ executeParams.flowInstancePay?.accountName }}</div>
              <div>备注：{{ executeParams.flowInstancePay?.remark }}</div>
              <div>收款码：<a-image :width="40" :src="executeParams.flowInstancePay?.payeeFileUrl" /></div>
            </div>
            <div style="color:#6B778C">
              <div>账号：{{ executeParams.flowInstancePay?.accountNum }}</div>
              <div>开户行：{{ executeParams.flowInstancePay?.accountBank }}</div>
              <div>其他照片： <a-image :width="40"
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" /></div>
            </div>
          </div>
        </a-form-item>
        <a-form-item v-model:value="remarkShow">
          <a-textarea v-model:value="executeParams.flowInstanceParams.remark" :placeholder="remakePlaceholder"
            :rows="4" />
        </a-form-item>
        <a-upload v-if="agreeShow" v-model:file-list="imgFileList" accept="image/*" list-type="picture"
          :remove="handleRemove" :before-upload="beforeUpload">
          <a-button :disabled="imgFileList.length > 0">
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
        <div style="display:flex;justify-content:end">
          <a-button type="primary" @click="handleOk" :loading="btnLoading">确认</a-button>
          <!-- <a-button @click="modalVisible = false">取消</a-button> -->
        </div>
      </a-form>
      <!-- executeParams.approveType.value === 1 -->
    </div>
  </a-modal>
  <a-modal v-model:visible="startPayShow" title="发起付款" width="40%" footer="" :confirmLoading="btnLoading" @ok="handleOk"
    @cancel="closeStartPay" :maskClosable="false" centered>
    <div>
      <a-form-item>
        <template #label>
          <div>
            <span style="color:#f00">*</span>选择付款方式
          </div>
        </template>
        <a-select style="width: 100%" v-if="startPayShow" @change="payTypeChange">
          <a-select-option :value="1">线上付款</a-select-option>
          <a-select-option :value="0">线下付款</a-select-option>
        </a-select>
      </a-form-item>
      <div class="content-div" v-show="executeParams.flowInstancePay.onlinePay !== null">
        <!-- 线上付款 -->
        <div class="online-pay" v-show="executeParams.flowInstancePay.onlinePay === 1">
          <div class="content-data">
            <label class="label-title">通联账号：</label>
            <span class="label-data">{{ procedureData.bizUserId }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">别名：</label>
            <span class="label-data">{{ procedureData.memberAlias }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">收款银行卡号：</label>
            <span class="label-data">{{ procedureData.accountNum }}</span>
          </div>
        </div>
        <!-- 线下付款 -->
        <div class="offline-pay" v-show="executeParams.flowInstancePay.onlinePay === 0">
          <div class="content-data">
            <label class="label-title">收款方式：</label>
            <span class="label-data">{{ procedureData.mode }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">户名：</label>
            <span class="label-data">{{ procedureData.accountName }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">账号：</label>
            <span class="label-data">{{ procedureData.accountNum }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">开户行：</label>
            <span class="label-data">{{ procedureData.accountBank }}</span>
          </div>
          <div class="content-data">
            <label class="label-title">收款码：</label>
            <span class="label-data">
              <a-image v-if="procedureData.payeeFileId" :width="20" :src="procedureData.payeeFileUrl || ''" />
            </span>
          </div>
        </div>

      </div>
      <a-form-item v-show="executeParams.flowInstancePay.onlinePay !== null">
        <template #label>
          <div>
            <span style="color:#f00">*</span>本次付款金额
          </div>
        </template>
        <div style="display:flex;align-items:center">
          <a-input prefix="￥" suffix="元" v-model:value="amount" style="flex:1" />
          <div style="color:#0066CC;font-size:12px;margin-left:10px">(当前未付款金额: ￥{{ procedureData.notSettledAmt }}元)</div>
        </div>
      </a-form-item>
      <a-form-item v-show="executeParams.flowInstancePay.onlinePay === 0">
        <template #label>
          <div>
            <span style="color:#f00">*</span>付款凭证
          </div>
        </template>
        <a-upload v-if="startPayShow" v-model:file-list="imgFileList" accept="image/*" list-type="picture"
          :remove="handleRemove" :before-upload="beforeUpload">
          <a-button :disabled="imgFileList.length > 0">
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
      </a-form-item>
      <div style="display:flex;justify-content:end">
        <a-space>
          <a-button type="primary" @click="submitPayment">提交</a-button>
        </a-space>
      </div>
    </div>
  </a-modal>
  <a-modal v-model:visible="remarkVisible" title="标记" width="30vw" :bodyStyle="{ height: '30vh', overflow: 'auto', }"
    @ok="handleRemarkOk" :confirmLoading="btnLoading" :maskClosable="false" centered>
    <a-form layout="vertical">
      <a-form-item>
        <a-textarea v-model:value="remarkParams.remark" placeholder="请输入你想得标记" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal :confirm-loading="copyLoading" v-model:visible="copyVisible" title="抄送" width="30vw"
    :bodyStyle="{ height: '50vh', overflow: 'auto', }" @ok="handleCopyOk" :maskClosable="false" centered>
    <a-form layout="vertical">
      <a-form-item label="抄送人">
        <a-select mode="multiple" v-if="copyVisible" show-search :filter-option="false" @focus="handleFocus"
          option-filter-prop="children" :not-found-content="fetching ? undefined : null" @change="handleChange"
          @select="handleSelect" @search="handleSearch">
          <a-select-option v-for="employee  in employees" :key="employee.id">{{ employee.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-textarea v-model:value="copyParams.content" placeholder="请输入抄送的内容" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 线下支付-补充资料-银行卡 -->
  <a-modal v-model:visible="bankFromShow" title="线下支付-资料填写" @ok="confirmBank" :confirmLoading="btnLoading">
    <a-form ref="bankFromRef" :model="executeParams.flowInstancePay" :rules="bankRules" layout="vertical">
      <a-form-item label="户名" name="accountName">
        <a-input v-model:value="executeParams.flowInstancePay.accountName" />
      </a-form-item>
      <a-form-item label="银行账号" name="accountNum">
        <a-input v-model:value="executeParams.flowInstancePay.accountNum" type="number" />
      </a-form-item>
      <a-form-item label="开户行" name="accountBank">
        <a-input v-model:value="executeParams.flowInstancePay.accountBank" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 补充资料-线上支付 -->
  <a-modal v-model:visible="onlineShow" title="线上支付-资料填写" @ok="confirmOnline" :confirmLoading="btnLoading">
    <a-form ref="onlineFormRef" :model="executeParams.flowInstancePay" :rules="onlineRules" layout="vertical">
      <a-form-item label="选择收款方类型" name="objectType">
        <a-select v-model:value="executeParams.flowInstancePay.objectType" @change="typeChange">
          <a-select-option :value="2">承运商</a-select-option>
          <a-select-option :value="1">客户</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="executeParams.flowInstancePay.objectType" :label="nameTitle" name="objectId">
        <a-select placeholder="搜索并选择" v-model:value="clientName" @search="searchClient" labelInValue :filterOption="false"
          show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in clientList" :key="item.id" @click="carrierClient(item)">
            {{ item.name + '-' + item.mobile }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="executeParams.flowInstancePay.objectType" :label="vipTitle" name="bizUserId">
        <a-select placeholder="选择会员账号" v-model:value="vipName">
          <a-select-option v-for="item in VIPaccountList" :key="item.id" @click="chooseVIP(item)">{{ item.memberAlias ||
            item.bizUserId
          }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 付款明细 -->
  <a-modal v-model:visible="parmentShow" width="50%" title="付款明细" footer="">
    <div>
      <PaymentRecord ref="paymentRecordRef" />
    </div>
  </a-modal>
   <!-- 自定义图片预览 -->
   <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></PictureViewer>
</template>
<script>
import { newDetail,detail, execute, taskRemark, copyRemark, getFlowInstancePay, offlinePay, startFlowPay } from '@/api/system/bpm/instance'
import { fileUpload as upload } from '@/api/marketing/modList'
import { intercoursePage, bindFlow } from '@/api/trans/finance/account'
import { list } from '@/api/system/employee'
import { getMemberRel } from '@/api/carrier/cloudFlash'
import { onMounted, reactive, shallowReactive, ref, toRefs, createVNode } from 'vue'
import { CheckCircleFilled, CameraOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { fuzzyAll } from '@/api/crm/carrer/carrier'
import { message, Modal } from 'ant-design-vue'
import { confirmWindow } from '@/utils/util'
import PictureViewer from '@/components/PictureViewer';
import PaymentRecord from './components/PaymentRecord'
import AssemblyDetail from './components/AssemblyDetail'
import QualityLossDetail from './components/QualityLossDetail'
import BillingApplication from './components/BillingApplication'
import RedRush from './components/RedRush'
import InvoiceModal from './components/invoiceModal'
import CancelModal from './components/cancelModal'
import Turnover from './components/Turnover'
import DataAuth from '@/views/system/employee/dataAuth'
export default {
  components: {
    DataAuth,
    CheckCircleFilled,
    CameraOutlined,
    AssemblyDetail,
    QualityLossDetail,
    Turnover,
    PaymentRecord,
    BillingApplication,
    RedRush,
    InvoiceModal,
    CancelModal,
    PictureViewer
  },
  props: {
    flowInstanceId: { type: String },
    flowInstanceTaskId: { type: String, default: '' },
    type: { type: String }
  },
  emits: ['ok'],
  setup (props, { emit }) {
    // console.log(props)  //传入的订单编号
    const bankFromRef = ref(null)
    const onlineFormRef = ref(null)
    const paymentRecordRef = ref(null)
    const state = reactive({
      visibleCancel:false,
      visibleInvoice:false,
      transDetailsVisible: false,
      parmentShow: false,
      modalVisible: false,
      newModalVisible: false,
      remarkShow: false,
      remarkVisible: false,
      btnLoading: false,
      copyVisible: false,
      copyLoading: false,
      bankFromShow: false,
      onlineShow: false,
      fetching: false,
      loading: false,
      startPayShow: false,
      agreeShow: false,
      timeOutId: null,
      clientName: null,
      vipName: null,
      flowInstanceId: props.flowInstanceId,
      modalTitle: '',
      nameTitle: '',
      vipTitle: '',
      type: props.type,
      transId: '',
      remake: '',
      amtTotal: 0,
      amount: null, // 本次付款金额
      amts: [],
      remakePlaceholder: '',
      procedureData: {},
      flowType: {},
      bizParams: {},
      instance: {},
      taskList: [],
      clientList: [], // 选择客户
      VIPaccountList: [], // 会员账号
      remarkParams: {
        flowInstanceTaskId: null,
        remark: ''
      },
      employees: [],
      copyParams: {
        copyPeopleIds: [],
        content: ''
      },
      columns: [
        {
          title: '金额',
          dataIndex: 'amt'
        },
        {
          title: '方式',
          dataIndex: '',
          slots: { customRender: 'onlinePay' }
        },
        {
          title: '银行卡',
          dataIndex: 'accountNum'
        },
        {
          title: '凭证',
          dataIndex: '',
          slots: { customRender: 'previewUrl' }
        },
        {
          title: '分账状态',
          dataIndex: 'splitStatus.label'
        },
        {
          title: '提现状态',
          dataIndex: 'withdrawStatus.label'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '回单查看',
          dataIndex: '',
          slots: { customRender: 'download' }
        }
      ],
      bankForm: {
        accountName: '', // 户名
        accountBank: '', // 开户行
        accountNum: '', // 银行账号
      },
      bankRules: {
        accountBank: { required: true, message: '请填写开户行', trigger: 'blur' },
        accountName: { required: true, message: '请填写户名', trigger: 'blur' },
        accountNum: [{ required: true, message: '请填写银行账号', trigger: 'blur' }]
      },
      onlineRules: {
        objectType: { required: true, message: '请选择收款方类型', trigger: 'blur', type: 'number' },
        objectId: { required: true, message: '请选择', trigger: 'blur' },
        bizUserId: { required: true, message: '请选择会员账号', trigger: 'blur' }
      },
      executeParams: {
        flowInstanceId: '',
        flowInstancTaskId: '',
        approveType: {},
        bizParams: {}, // 业务参数
        flowInstanceParams: {}, // 流程参数
        flowInstancePay: {
          onlinePay: null,
          objectType: null,
          flowInstanceId: '',
          objectId: '',
          accountName: '', // 户名
          accountBank: '', // 开户行
          accountNum: '', // 银行账号
          bizUserId: '', // 会员账号
        }
      },
      mirrorFlowInstancePay: {},
      imgFileList: [],
      // eventList: ['turnover'],
      // currentStep: 0,
      // eventNameList: { turnover: '流水绑定', lastStep: '审核意见' },
      form: {
        uniqueNumber: '',
        accountNumber: '',
        tradeTypeName: '',
        tradeAmtStart: '',
        tradeAmtEnd: '',
        weAccount: '',
        tradeTime: [],
        tradePurpose: '',
        confirm: ''
      },
      turnoverData: [],
      selectedRowKeys: [],
      selectedRows: [],
      turnoverLoading: false,
      chooseAmt: 0,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '30', '60', '80', '500'],
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadIntercourseData()
        }
      }
    })
// 预览图片
const  lookImgID = ref([]) 
const visibleImgPic = ref(false)
const lookImg = (item) => {
   lookImgID.value = [item]
   visibleImgPic.value = true
 }
    const shallowState = shallowReactive({
      assemblyType: AssemblyDetail
    })
    const agreeCancel = () => {
      state.visibleCancel = true
    }
    const agreeInvoice = () => {
      state.visibleInvoice = true
    }
   
    const loadData = () => {
      state.loading = true
      let reqDate = props.type=='myPending'?newDetail:detail
      reqDate(props.flowInstanceId).then(async(res)  => {
        // console.log(res)  // 订单详情数据
        if (res.code === 10000) {
          console.log('数据',res.data);
          state.flowType = res.data.flowType
          state.bizParams = res.data.bizParams
          state.instance = res.data.instance
          state.taskList = res.data.taskList
          getFlowInstancePayData(props.flowInstanceId)
          if (res.data.flowInstancePay) state.executeParams.flowInstancePay = res.data.flowInstancePay
          state.executeParams.flowInstanceId = props.flowInstanceId
          state.executeParams.flowInstancTaskId = props.flowInstanceTaskId
          // if (state.eventList.length) state.eventList.push('lastStep')
          state.amts = []
          if (state.flowType.value === 0 || state.flowType.value === 2) {
            state.amtTotal = 0
            state.amtTotal = Number(res.data.bizParams.collectionAccount.amt)
            state.amts.push(res.data.bizParams.collectionAccount.amt)
            shallowState.assemblyType = AssemblyDetail
          } else if (state.flowType.value === 1 || state.flowType.value === 3 || state.flowType.value === 4) {
            state.amtTotal = 0
            if (res.data.bizParams.applyAccounts && res.data.bizParams.applyAccounts.length > 0) {
              res.data.bizParams.applyAccounts.forEach(item => {
                state.amtTotal += Number(item.amt)
                state.amts.push(`-${item.amt}`)
              })
            }
            shallowState.assemblyType = AssemblyDetail
          }else if(state.flowType.value === 6){
               state.amtTotal = 0
               shallowState.assemblyType = BillingApplication
          }else if(state.flowType.value === 7 || state.flowType.value === 8){
            state.amtTotal = 0
            shallowState.assemblyType =  RedRush
          }
          else {
            shallowState.assemblyType = QualityLossDetail
            state.amtTotal = Number(res.data.bizParams.qualityLossAmt)
            state.amts.push(res.data.bizParams.qualityLossAmt)
          }
        }
      }).finally(() => { state.loading = false })
    }
    onMounted(loadData)
    const getFlowInstancePayData = (flowInstanceId) => {
      getFlowInstancePay(flowInstanceId).then(res => {
        if (res.code === 10000 && res.data) {
          state.procedureData = res.data
        }
      })
    }
    const agree = () => {
      if (state.executeParams.flowInstancePay.bizUserId != '' && state.executeParams.flowInstancePay.bizUserId != null && state.instance.handType === 11) {
        state.newModalVisible = true
        setTimeout(() => {
          paymentRecordRef.value?.getFlowPayRecordData(props.flowInstanceId)
        }, 100)
      } else {
        state.modalVisible = true
      }
      state.remarkShow = false
      state.executeParams.approveType.value = 1
      // state.modalTitle = '审批同意意见'
      state.modalTitle = '流程审核'
      state.remakePlaceholder = '请输入同意理由'
      state.pagination.current = 1
      // loadIntercourseData()
    }
    const initLoadIntercourseData = () => {
      state.pagination.current = 1
      loadIntercourseData()
    }

    const loadIntercourseData = () => {
      state.turnoverLoading = true
      intercoursePage({
        ...state.form,
        confirm: 0,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.turnoverData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.turnoverLoading = false
      })
    }
    const tableChange = page => {
      state.pagination = page
      loadIntercourseData()
    }
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      state.selectedRowKeys = selectedRowKeys
      const len = state.selectedRows.length
      if (len === undefined || len === 0) {
        state.selectedRows = selectedRows.map(item => { return { uniqueNumber: item.uniqueNumber, id: item.id, tradeAmt: item.tradeAmt } })
      } else {
        selectedRows.forEach(item => {
          if (state.selectedRows.filter(s => s.id !== item.id).length === len) {
            state.selectedRows.push({ uniqueNumber: item.uniqueNumber, id: item.id, tradeAmt: item.tradeAmt })
          }
        })
      }
      let nowSelect = []
      if (state.selectedRowKeys.length === 0) {
        nowSelect = []
      } else {
        state.selectedRowKeys.forEach(i => {
          const item = state.selectedRows.filter(s => s.id === i)
          if (item.length === 1) {
            nowSelect.push({ uniqueNumber: item[0].uniqueNumber, id: item[0].id, tradeAmt: item[0].tradeAmt })
          }
        })
      }
      state.selectedRows = nowSelect
      let chooseAmt = 0
      state.selectedRows.forEach(item => {
        chooseAmt += Number(item.tradeAmt)
      })
      state.chooseAmt = chooseAmt.toFixed(2)
    }

    const refuse = () => {
      state.modalVisible = true
      state.remarkShow = true
      state.executeParams.approveType.value = 5
      state.modalTitle = '审批拒绝意见'
      state.remakePlaceholder = '请输入拒绝理由'
    }
    const remarkFlow = () => {
      state.modalVisible = true
      state.remarkShow = true
      state.executeParams.approveType.value = 9
      state.modalTitle = '评论意见'
      state.remakePlaceholder = '请输入评论'
    }
    const reject = () => {
      state.modalVisible = true
      state.remarkShow = false
      state.executeParams.approveType.value = 2
      state.modalTitle = '审批退回意见'
      state.remakePlaceholder = '请输入退回理由'
    }

    const remarkTask = () => {
      state.remarkVisible = true
    }

    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }

    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }

    const handleOk = () => {
      if (state.executeParams.approveType.value === 9 || state.executeParams.approveType.value === 5 || state.executeParams.approveType.value === 2) {
        if (state.executeParams.flowInstanceParams.remark === undefined || state.executeParams.flowInstanceParams.remark === '') {
          message.error('必须填写原因')
          return
        }
      }
      state.btnLoading = true
      confirmWindow('', requireAPI)
      function requireAPI (resolve) {
        if (state.executeParams.approveType.value === 5 || state.executeParams.approveType.value === 2 || state.executeParams.approveType.value === 9) {
          submitFlow(resolve)
        } else if (state.instance.handType !== 1) {
          submitFlow(resolve)
        } else {
          if (Math.abs(state.chooseAmt) === Math.abs(state.amtTotal)) {
            if (Math.abs(state.amtTotal) === 0) {
              submitFlow(resolve)
            } else {
              bindFlow({
                flowInstanceId: state.instance.instanceId,
                intercourseIds: state.selectedRows.map(item => item.id)
              }).then(res => {
                if (res.code === 10000) {
                  submitFlow(resolve)
                }
              }).finally(() => { state.btnLoading = false, resolve() })
            }
          } else {
            // onlinePay 1为线上 0位线下
            if (state.executeParams.flowInstancePay.onlinePay === 1) {
              bindFlow({
                flowInstanceId: state.instance.instanceId,
                intercourseIds: state.selectedRows.map(item => item.id)
              }).then(res => {
                if (res.code === 10000) {
                  submitFlow(resolve)
                }
              }).finally(() => { state.btnLoading = false, resolve() })
            } else {
              Modal.warning({
                content: '流水金额选择不对'
              })
              state.btnLoading = false
            }
          }
        }
      }
    }
    const submitPayment = () => {
      // state.executeParams.flowInstancePay.onlinePay    onlinePay 1为线上 0位线下
      // 接口请求--线上
      function onlineAPI (resolve) {
        startFlowPay({
          amount: state.amount,
          bizUserId: state.procedureData.bizUserId,
          extend: {},
          flowInstanceId: props.flowInstanceId
        }).then(res => {
          if (res.code === 10000) {
            loadData()
            setTimeout(() => {
              paymentRecordRef.value?.getFlowPayRecordData(props.flowInstanceId)
            }, 10)
            state.bankFromShow = false
            state.onlineShow = false
            state.startPayShow = false
            state.executeParams.flowInstancePay.onlinePay = null
            state.amount = 0
            message.success(res.msg)
          }
        }).finally(() => { state.btnLoading = false, resolve() })
      }
      // 接口请求-线下
      function offlineAPI (resolve) {
        const formData = new FormData()
        state.imgFileList.forEach(file => {
          formData.append('file', file.originFileObj)
        })
        upload(formData).then(res => {
          if (res.code === 10000) {
            let files = [res.data.id]
            offlinePay({
              amount: state.amount,
              fileIds: files,
              flowInstanceId: props.flowInstanceId
            }).then(res => {
              if (res.code === 10000) {
                loadData()
                setTimeout(() => {
                  paymentRecordRef.value?.getFlowPayRecordData(props.flowInstanceId)
                }, 10)
                state.bankFromShow = false
                state.onlineShow = false
                state.startPayShow = false
                state.executeParams.flowInstancePay.onlinePay = null
                state.amount = 0
                state.imgFileList = []
                message.success(res.msg)
              }
            }).finally(() => { state.btnLoading = false, resolve() })
          }
        })
      }
      if (state.executeParams.flowInstancePay.onlinePay === 1 && state.amount * 1 <= state.procedureData.notSettledAmt * 1) { // 线上
        if (state.amount > 0) {
          state.btnLoading = true
          confirmWindow(`确认支付${state.amount}元`, onlineAPI)
        } else message.error('请输入付款金额或金额数目不正确')
      } else { // 线下
        if (state.amount > 0 && state.amount * 1 <= state.procedureData.notSettledAmt * 1) {
          if (state.imgFileList.length > 0) {
            state.btnLoading = true
            confirmWindow(`确认支付${state.amount}元`, offlineAPI)
          } else message.error('请上传付款凭证')
        } else message.error('请输入付款金额或金额数目不正确')
      }
    }
    const delSelectedRow = (id) => {
      state.selectedRows = state.selectedRows.filter(item => item.id !== id)
      state.selectedRowKeys = state.selectedRowKeys.filter(item => item !== id)
      let chooseAmt = 0
      state.selectedRows.forEach(item => {
        chooseAmt += Number(item.tradeAmt)
      })
      state.chooseAmt = chooseAmt.toFixed(2)
    }
    const handleRemarkOk = () => {
      state.remarkParams.flowInstanceTaskId = state.executeParams.flowInstancTaskId
      state.btnLoading = true
      taskRemark(state.remarkParams).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.remarkVisible = false
          emit('ok')
        }
      }).finally(() => { state.btnLoading = false })
    }
    const submitFlow = (resolve) => {
      console.log(state.executeParams.flowInstancePay.onlinePay);
      if (state.executeParams.flowInstancePay.onlinePay !== null) {
        if (state.imgFileList.length > 0) {
          const formData = new FormData()
          state.imgFileList.forEach(file => {
            formData.append('file', file.originFileObj)
          })
          upload(formData).then(res => {
            if (res.code === 10000) {
              state.executeParams.flowInstanceParams.fileId = res.data.id
              requireAPI()
            }
          }).finally(() => { state.btnLoading = false, resolve() })
        } else {
          requireAPI()
        }
      } else {
        // if (state.executeParams.approveType.value !== 9 && state.executeParams.approveType.value !== 2 && state.executeParams.approveType.value !== 5) {
        // message.error('请选择收款方式')
        // } else {
        if (state.imgFileList.length > 0) {
          const formData = new FormData()
          state.imgFileList.forEach(file => {
            formData.append('file', file.originFileObj)
          })
          upload(formData).then(res => {
            if (res.code === 10000) {
              state.executeParams.flowInstanceParams.fileId = res.data.id
              requireAPI(resolve)
            }
          }).finally(() => { state.btnLoading = false, resolve() })
        } else {
          requireAPI(resolve)
        }
        // }
      }
    }
    const invoiceOk = (msg) => {
      Modal.confirm({
        title: () => '确认操作？',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk () {
          requireAPI('',msg)
        },
        onCancel () {
         
        }
      })
     
    }
    const invoiceOkCancel = (msg) => {
      Modal.confirm({
        title: () => '确认操作？',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk () {
          requireAPI('',msg)
        },
        onCancel () {
         
        }
      })
    }
    const requireAPI = (resolve,msg) => {
      if (state.executeParams.flowInstanceParams.remark === undefined || state.executeParams.flowInstanceParams.remark === '') {
        state.executeParams.flowInstanceParams.remark = ''
      }
      let object = JSON.stringify(state.executeParams)
      delete JSON.parse(object).flowInstancePay
      if(msg) {
        state.executeParams.bizParams = msg
        // state.executeParams.flowInstanceParams.remark = msg.remake
        state.executeParams.approveType = 1
      }
      
      execute(state.executeParams).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.modalVisible = false
          state.bankFromShow = false
          state.onlineShow = false
          state.agreeShow = false
          state.executeParams.flowInstanceParams.remark = ''
          state.imgFileList = []
          state.executeParams.flowInstancePay = JSON.parse(object).flowInstancePay
          //   loadData()
          if (state.modalTitle !== '评论意见') emit('ok')
        }
      }).finally(() => {
         state.btnLoading = false 
         if(resolve){
           resolve() 
         }
    })
    }
    const copyFlow = () => {
      state.copyVisible = true
    }
    const handleCopyOk = () => {
      if (state.copyParams.copyPeopleIds.length <= 0) {
        message.error('必须选择抄送人')
        return
      }
      const set = new Set(state.copyParams.copyPeopleIds)
      state.copyParams.copyPeopleIds = Array.from(set)
      state.copyLoading = true
      state.copyParams.instanceId = state.instance.instanceId
      copyRemark(state.copyParams).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.remarkVisible = false
          state.copyVisible = false
          state.copyParams.content = ''
          loadData()
        }
      }).finally(() => {
        state.copyLoading = false
      })
    }
    const handleSelect = (value, option) => {
      state.employees.forEach(e => {
        if (e.id === value) {
          state.copyParams.name = e.name
          state.copyParams.copyPeopleIds.push(e.id)
          // state.copyParams.copyPeopleIds = [e.id]
        }
      })
    }
    const handleSearch = value => {
      const reg = /^[A-Za-z]+$/
      if (value !== '') {
        if (!reg.test(value)) {
          state.fetching = false
          list({ name: value }).then((res) => {
            if (res.code === 10000) {
              state.employees = res.data
              state.fetching = true
            }
          })
        }
      }
    }
    const typeChange = (e) => {
      state.executeParams.flowInstancePay.objectType = e
      state.executeParams.flowInstancePay.objectId = ''
      state.clientName = null
      state.vipName = null
      state.clientList = []
      state.VIPaccountList = []
      if (e === 1) {
        state.nameTitle = '选择客户'
        state.vipTitle = '客户会员账号'
      } else {
        state.nameTitle = '选择承运商'
        state.vipTitle = '承运商会员账号'
      }
    }
    const confirmBank = () => {
      bankFromRef.value.validate()
        .then(() => {
          state.btnLoading = true
          state.executeParams.approveType.value = 1
          state.executeParams.flowInstancePay.flowInstanceId = props.flowInstanceId
          requireAPI()
        })
    }
    const confirmOnline = () => {
      onlineFormRef.value.validate()
        .then(() => {
          state.btnLoading = true
          state.executeParams.approveType.value = 1
          state.executeParams.flowInstancePay.flowInstanceId = props.flowInstanceId
          requireAPI()
        })
    }
    const searchClient = (value) => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzyAll({ name: value, type: state.executeParams.flowInstancePay.objectType }).then(res => {
            if (res.code === 10000) { state.clientList = res.data }
            else state.clientList = []
          }).finally(() => { state.fetching = false })
        }, 1000)
      } else {
        state.clientList = []
      }
    }
    const carrierClient = item => {
      state.executeParams.flowInstancePay.objectId = item.id
      getMemberRel({ objectId: state.executeParams.flowInstancePay.objectId }).then(res => {
        if (res.code === 10000) {
          state.VIPaccountList = res.data
        }
      })
    }
    const closeStartPay = () => {
      state.startPayShow = false
      state.executeParams.flowInstancePay.onlinePay = null
    }
    const chooseVIP = item => {
      state.executeParams.flowInstancePay.bizUserId = item.bizUserId
    }
    const payTypeChange = e => {
      state.executeParams.flowInstancePay.onlinePay = e
      state.amount = null
      if (e === 1) {
        if (state.procedureData.notSettledAmt * 1 < 30000) {
          state.amount = state.procedureData.notSettledAmt
        }
      }
    }
    const download = record => {
      receiptDownload({
        bizOrderNo: record.bizOrderNo
      }).then(res => {
        if (res.code === 10000) {
          window.open(res.data.url, '_blank')
        }
      })
    }
    const paymentDetail = () => {
      state.parmentShow = true
      setTimeout(() => {
        paymentRecordRef.value?.getFlowPayRecordData(props.flowInstanceId)
      }, 100)
    }
    return {
      ...toRefs(state),
      ...toRefs(shallowState),
      bankFromRef,
      onlineFormRef,
      paymentRecordRef,
      agree,
      download,
      typeChange,
      payTypeChange,
      onSelectChange,
      searchClient,
      paymentDetail,
      carrierClient,
      confirmBank,
      confirmOnline,
      refuse,
      chooseVIP,
      remarkFlow,
      copyFlow,
      remarkTask,
      reject,
      closeStartPay,
      tableChange,
      handleRemove,
      beforeUpload,
      handleRemarkOk,
      handleCopyOk,
      handleOk,
      submitPayment,
      loadIntercourseData,
      initLoadIntercourseData,
      handleSelect,
      handleSearch,
      delSelectedRow,
      agreeInvoice,
      invoiceOk,
      agreeCancel,
      invoiceOkCancel,
      lookImgID,
      lookImg,
      visibleImgPic
    }
  }
}
</script>
<style lang="less" scoped>
.loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, .5)
}

.content-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #97C1EA;
  background: #F0F6FC;

  .show-content {
    width: 30%;

    .title-label {
      color: #6B778C;
      font-weight: 400;
    }

    .content {
      color: #344563;
      font-weight: 700;
    }
  }
}

.pay-btn {
  margin: 15px 0;
  color: #fff;
  background: #0066CC;
}

.online-pay {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .content-data {
    width: 50%;
    display: flex;

    .label-title {
      display: block;
      width: 100px;
      text-align: right;
    }
  }
}

.offline-pay {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .content-data {
    width: 50%;
    display: flex;

    .label-title {
      display: block;
      width: 100px;
      text-align: right;
    }
  }
}
</style>
