import { postBodyRequest, exportRequest } from '@/utils/axios'

export function runningPage (params) {
  return postBodyRequest('/trans/finance/account/running', params)
}

export function runningExport (params, fileName) {
  return exportRequest('/trans/finance/account/running/export', params, fileName)
}

export function intercoursePage (params) {
  return postBodyRequest('/trans/finance/account/intercourse', params)
}

export function intercourseExport (params, fileName) {
  return exportRequest('/trans/finance/account/intercourse/export', params, fileName)
}

export function batchIntercourseFile (fileId, params) {
  return postBodyRequest(`/trans/finance/account/batchIntercourse/file/${fileId}`, params)
}

export function bindFlow (params) {
  return postBodyRequest('/trans/finance/account/bind/flow', params)
}
export function intercourseCreate (params) {
  return postBodyRequest('/trans/finance/account/intercourse/create', params)
}

export function intercourseSplit (params) {
  return postBodyRequest('/trans/finance/account/intercourse/split', params)
}

// 生成公司内部账户
export function accountAdd (params) {
  return postBodyRequest('/trans/finance/account/add', params)
}

export function accountPage (params) {
  return postBodyRequest('/trans/finance/account/balance/page', params)
}

export function deleteData (id) {
  return postBodyRequest(`/trans/finance/account/intercourse/${id}/delete`)
}

export function runningDetail (id) {
  return postBodyRequest(`/trans/finance/account/intercourse/${id}/detail`)
}

//财务发票-可用额度查看
export function provideUsableQuota (invoiceId) {
  return postBodyRequest(`/trans/finance/bill-invoice/${invoiceId}/provide-usable-quota`)
}
