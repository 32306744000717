<template>
<div class="redBox">
   <div class="title">发票信息</div>
   <div class="flex wrap  " style="width: 100%;color: #6B778C;margin-top: 15px">
         <div v-for="item in invoiceInfo" :key="item.id" style="width: 33%;">
              {{ item.title }}：<span v-if="item.title!=='发票'">{{ item.value }}</span><span v-if="item.title=='发票'"><a @click="lookImg('',item.value)">查看</a></span>
         </div>
    </div>
    <div class="title m-t2">理由</div>
    <div style="color: #6B778C;width: 100%;" class="m-t1 flex wrap">
       <div style="width: 33%;"><div style="padding-right:20px">原因：{{ state.remark }}</div></div>
       <span >图片：<a v-if="state.applyFileIds.length > 0" @click="lookImg('',state.applyFileIds)">查看</a></span>
    </div>
    <template v-if="state.invoiceSubject?.cancelInvoiceFileTime || state.invoiceSubject?.cancelInvoiceFileId">
      <div class="title m-t2">结果</div>
    <div style="color: #6B778C;width: 100%;" class="m-t1 flex wrap">
       <div style="width: 33%;">处理时间：{{ state.invoiceSubject.cancelInvoiceFileTime}}</div>
       <div  style="width: 33%;">凭证：<a v-if="state.invoiceSubject.cancelInvoiceFileId" @click="lookImg('',state.invoiceSubject.cancelInvoiceFileId)">查看</a></div>
       <div  style="width: 33%;">备注：{{ state.invoiceSubject.cancelProcessRemark}}</div>
    </div>
    </template>
    <div style="font-weight: bold;font-size: 16px;margin:15px 0" >开票车辆</div>
    <a-table
     :rowKey="(record, index) => { return index;}"
      bordered
      :columns="columnsOrder"
      :data-source="state.vehicleInvoices"
      size="small"
      :pagination="false"
     >
     <template #vinNo="{ record } ">
      {{ record.vinNo }} {{ record.brand }} {{ record.model }}
     </template>
     <template #startAddress="{ record }">
      <div>起始地：{{ record.startAddress }}</div>
       <div>目的地：{{ record.endAddress}}</div>
     </template>
     <template #settlementStatus="{  record }">
         {{ record.settlementStatus.label }}
     </template>
     </a-table>
        <!-- 自定义图片预览 -->
   <PictureViewer v-model:visible="visibleImgPic" :lookImgID="lookImgID"></PictureViewer>
</div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import PictureViewer from '@/components/PictureViewer'
const props = defineProps({
  bizParams: {
    type: Object,
    default: {}
  }
})
const lookImgID = ref([])
const visibleImgPic = ref(false)
const state = reactive({
  applyFileIds: props.bizParams.applyFileIds,
  invoiceSubject: props.bizParams.invoiceSubject, // 合同信息
  vehicleInvoices: props.bizParams.vehicleInvoices, // 车辆
  remark: props.bizParams.remark
})
// 预览图片
const lookImg = (str, item) => {
  if (str) {
    if (typeof item[str] === 'string') {
      lookImgID.value = [item[str]]
      visibleImgPic.value = true
    } else {
      lookImgID.value = item[str]
      visibleImgPic.value = true
    }
  } else {
    if (typeof item === 'string') {
      lookImgID.value = [item]
      visibleImgPic.value = true
    } else {
      lookImgID.value = item
      visibleImgPic.value = true
    }
  }
}
const invoiceInfo = ref([
  {
    title: '发票号',
    value: '',
    key: 'invoiceNum'
  },
  {
    title: '金额',
    value: '',
    key: 'amt'
  },
  {
    title: '购方单位',
    value: '',
    key: 'purchasingUnit'
  },
  {
    title: '税号',
    value: '',
    key: 'taxIdentNum'
  },
  {
    title: ' 流程编号',
    value: '',
    key: 'flowInstanceId'
  },
  {
    title: ' 发票类型',
    value: '',
    key: 'invoiceMethod',
    obj: 'label'
  },
  {
    title: ' 开票主体',
    value: '',
    key: 'invoiceCarrier'
  },
  {
    title: '税率',
    value: '',
    key: 'taxRate'
  },
  {
    title: '税目',
    value: '',
    key: 'taxItem'
  },
  {
    title: '开票时间',
    value: '',
    key: 'invoiceFileTime'
  },
  {
    title: '发票',
    value: '',
    key: 'invoiceFileId'
  }
])
const MENU = {
  '个人':'身份证号',
  '政府单位':'组织机构代码',
  '企业':'税号'
}
const columnsOrder = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' }
  },
  {
    title: '起点-终点',
    dataIndex: 'startAddress',
    key: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    }
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key: 'customerName',
    align: 'center'
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center'
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '合同金额',
    dataIndex: 'invoiceAmt',
    key: 'invoiceAmt',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key: 'settlementStatus',
    align: 'center',
    slots: {
      customRender: 'settlementStatus'
    }

  }
])
onMounted(() => {
 invoiceInfo.value[3].title = state.invoiceSubject.invoiceTypeName? MENU[state.invoiceSubject.invoiceTypeName] :'税号'
  invoiceInfo.value.forEach(item => {
    if (item.obj) {
      item.value = state.invoiceSubject[item.key][item.obj]
    } else {
      item.value = state.invoiceSubject[item.key]
    }
  })
 
})
</script>

<style lang="less" scoped>
.redBox{
  margin-top: 10px;
  padding: 15px;
  background: #fff;

  .title{
  color: #333;
  font-size: 16px;
  font-weight:bold;
  }
}
</style>
